<template>
  <div class="container">
    <div class="block">
      <v-card class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Вход</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              label="Логин"
              name="login"
              prepend-icon="mdi-account"
              type="text"
              v-model="username"
            ></v-text-field>

            <v-text-field
              id="password"
              label="Пароль"
              name="password"
              prepend-icon="mdi-lock"
              type="password"
              v-model="password"
            ></v-text-field>
            <p class="red--text ml-10 mt-n4" v-if="isErrorAuth">Неправильное имя или пароль!</p>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="loginUser" @keypress.enter="loginUser"
            >Войти</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
      return {
        isErrorAuth: false,
        username: '',
        password: '',
        wrongCred: false // activates appropriate message if set to true
      }
    },
    methods: {
      loginUser () { // call loginUSer action
        this.$store.dispatch('loginUser', {
          username: this.username,
          password: this.password
        })
            .then(() => {
              this.wrongCred = false
              this.$router.push({ path: '/' })
            })
          .catch(err => {
            console.log(err)
            this.isErrorAuth = true;
            this.wrongCred = true // if the credentials were wrong set wrongCred to true
          })
        }
      }
};
</script>

<style>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.block {
  max-width: 500px;
  width: 100%;
}
</style>
